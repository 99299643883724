<template>
  <div class="wrapper w-100">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12">
          <b-card header-tag="header">
            <div slot="header">
              <i class="fa fa-th-large"></i><strong> Récoltes</strong>
              <info-message>
                Une récolte marque la fin d'une production
              </info-message>
              <div class="card-header-actions">
                <b-button variant="primary" class="createHarv" :to="createHarvestRoute">
                  <i class="fa fa-plus"></i>
                  Créer une récolte
                </b-button>
              </div>
            </div>
            <b-modal title="Informations supplémentaires" class="modal-primary"
                     v-model="addNewInformationsEndHarvestModal"
                     @hide="onModalClose" :size="sizeToDisplay">
              <template v-if="fetchingExistingWarehouse">
                <div class="text-center">
                  <b-spinner></b-spinner>
                  <br>
                  <!--todo customizer tous les messages de chargement-->
                  Recherche des entrepôts...
                </div>
                <div slot="modal-footer"></div>
              </template>
              <template v-else>
                <b-form-row>
                  <b-col :cols="widthToDisplay"
                         v-for="(item,indice) in endHarvestCultureQuantities">
                    <b-card border-variant="light" :header="'Culture de '+item.cultureName" class="text-center">
                      <c-input container-class="mb-3 mt-1" type="select" label="Selectionnez un entrepôt"
                               v-model="item.warehouseId"
                               :options="warehouses"
                               :state="endHarvestCultureQuantitiesState[indice].warehouseId">
                        <template slot="first">
                          <option :value="null" disabled>-- Sélectionnez un entrepôt --</option>
                        </template>
                        Veuillez sélectionner un entrepôt
                      </c-input>
                      <c-input container-class="mb-3" type="quantity" label="Quantité réelle recoltée"
                               placeholder="Ex: 32000"
                               v-model="item.realQuantity" unit="Kg"
                               :state="endHarvestCultureQuantitiesState[indice].realQuantity">
                        Veuillez spécifier une quantité
                      </c-input>
                    </b-card>
                  </b-col>
                </b-form-row>
                
                <div slot="modal-footer" class="w-100 text-center">
                  <!--todo penser à integrer ce composant partout ou c'est necessaire-->
                  <button-spinner variant="danger" type="submit" class="px-4 mr-3" @click="onEndHarvest()"
                                  :fetching="fetchingEnd">
                    Clôturer effectivement vos récoltes
                  </button-spinner>
                  <b-button variant="secondary" class="ml-2">
                    Annuler
                  </b-button>
                </div>
              </template>
            </b-modal>
            <div class="text-center" v-if="fetchingHarvestList">
              <b-spinner></b-spinner>
              <br>
              Chargement...
            </div>
            <b-tabs v-else-if="harvests.length !=0" content-class="mt-3 border-0" v-model="harvestTabOpen">
              <b-tab v-for="(tab,index) in tabs">
                <div slot="title" class="text-dark">
                  <div :id="'v-tab-'+index">
                    <i :class="tab.icon"></i> {{tab.title}} ({{tab.harvests.length}})
                  </div>
                </div>
                <b-row class="exploitations">
                  <p class="text-center" v-if="tab.harvests.length == 0">
                    Vous n'avez aucune récolte
                    ({{tab.title}}).
                  </p>
                  <template v-else>
                    <b-col md="6" lg="4" xl="4" v-for="harvest in tab.harvests">
                      <b-card no-body>
                        <div slot="header">
                          {{harvest.name}}
                          <div class="card-header-actions">
                            <b-link href="#" class="card-header-action btn-setting">
                              <i class="icon-bell"></i>
                              <b-badge pill variant="danger">0</b-badge>
                              <b-badge pill variant="success">0</b-badge>
                            </b-link>
                          </div>
                        </div>
                        <b-list-group flush>
                          <b-list-group-item>
                            <i class="fa fa-clock-o mr-2 text-dark"></i>{{tab.timeTitle + ' ' + harvest.currentDate}}
                          </b-list-group-item>
                          <b-list-group-item>
                            <i class="icon-layers mr-2 text-secondary"></i>
                            Cultivée sur
                            <parcel-name @click="parcelUrl(harvest.production.parcel.id)">
                              {{harvest.production.parcel.name}}
                            </parcel-name>
                          </b-list-group-item>
                          <b-list-group-item>
                            <i class="fa fa-leaf mr-2 text-success"></i>{{harvest.culturesToDisplay.join(' , ')}}
                          </b-list-group-item>
                        </b-list-group>
                        <b-card-body class="text-muted">
                          {{harvest.description}}
                          <br>
                        </b-card-body>
                        <div slot="footer" class="mt-2">
                          <b-button variant="success" class="mr-2" :to="harvest.url" v-b-tooltip.hover
                                    title="Ouvrir la récolte">
                            <i class="icon-size-fullscreen"></i>
                          </b-button>
                          <!--todo mettre le to pour la redirection-->
                          <b-button variant="secondary" class="mr-2"
                                    v-if="tab.type == 'planned' || tab.type == 'blocked'" v-b-tooltip
                                    :title="tab.type == 'blocked' ? 'Relancer la récolte':'Lancer la récolte'"
                                    @click="launchHarvest(harvest,'save-start')">
                            <!--Lancer ou relancer une production-->
                            <template v-if="harvest.launching">
                              <b-spinner small class="mr-1"></b-spinner>
                              Lancement...
                            </template>
                            <i class="fa fa-play" v-else></i>
                          </b-button>
                          <b-button variant="secondary" class="mr-2" v-if="tab.type != 'planned'" v-b-tooltip.hover
                                    title="Tableau de bord" :to="harvest.url + '/dashboard'">
                            <!--Tableau de bord-->
                            <i class="fa fa-line-chart"></i>
                          </b-button>
                          <b-button variant="danger" class="mr-2" v-if="tab.type == 'running'" v-b-tooltip.hover
                                    title="Clôturer la récolte" @click="launchHarvest(harvest,'close')">
                            <!--Cloturer une production-->
                            <template v-if="harvest.ending">
                              <b-spinner small class="mr-1"></b-spinner>
                              Clôture...
                            </template>
                            <i class="fa fa-ban" v-else></i>
                          </b-button>
                          <b-button variant="danger" class="mr-2" v-if="tab.type == 'abandoned'" v-b-tooltip.hover
                                    title="Supprimer la récolte">
                            <!--Supprimer une production-->
                            <i class="fa fa-trash"></i>
                          </b-button>
                        </div>
                      </b-card>
                    </b-col>
                  </template>
                </b-row>
              </b-tab>
            </b-tabs>
            <p class="text-center" v-else>Vous n'avez crée aucune récolte.</p>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import {Api, Toast, IO} from "../../../helpers/index"
  import {Regex} from "../../../helpers";
  
  export default {
    name: "ExploitationHarvests",
    title: "PIA - Récoltes",
    data() {
      return {
        harvests: [],
        error: null,
        fetchingHarvestList: false,
        harvestTabOpen: 0,
        
        addNewInformationsEndHarvestModal: false,
        fetchingExistingWarehouse: false,
        fetchingEnd: false,
        submittedAllCutlureQuantities: false,
        warehouses: [],
        endHarvestWarehouse: null,
        currentHarvest: null,
        cultureRealQuantity: '',
        endHarvestCultureQuantities: [],
  
  
        myOptions: {
          useKeyboardNavigation: false,
          labels: {
            buttonSkip: 'Quittez',
            buttonPrevious: 'Précédent',
            buttonNext: 'Suivant',
            buttonStop: 'Terminé'
          }
        },
      }
    },
    mounted(){
    
    },
    created() {
      this.fetchingHarvestList = true
      Api.get('/exploitation/production/harvest/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            let isExistsHarvestRunning = false
            let currentDate = ''
            this.harvests = res.data.data.map(item => {
              switch (item.status.name) {
                case 'RUNNING':
                  isExistsHarvestRunning = true
                  currentDate = item.startDate
                  break;
                case 'PLANNED':
                  currentDate = item.startDate
                  break;
                case 'TERMINATED':
                  currentDate = item.endDate
                  break;
                case 'BLOCKED':
                  currentDate = item.blockedDate
                  break;
                default:
                  currentDate = item.endDate
                  break;
              }
              return {
                ...item,
                url: '/exploitation/' + this.exploitationId + '/production/harvest/' + item.id,
                culturesToDisplay: item.cultures.map(culture => {
                  const name = culture.name
                  const quantity = culture.realQuantity ? culture.realQuantity + ' Kg' : '--'
                  return name + '(' + quantity + ')'
                }),
                currentDate: currentDate.split(' ').join(' à '),
                launching: false,
                ending: false
              }
            })
            // to open a tab that has at least one production
            if (!isExistsHarvestRunning && this.harvests.length != 0)
              switch (this.harvests[0].status.name) {
                case 'TERMINATED' :
                  this.harvestTabOpen = 1
                  break
                case 'PLANNED' :
                  this.harvestTabOpen = 2
                  break
                case 'ABANDONED' :
                  this.harvestTabOpen = 3
                  break
                default :
                  this.harvestTabOpen = 4
              }
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingHarvestList = false
        })
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      },
    },
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      createHarvestRoute() {
        return {
          name: 'ExploitationCreateHarvest',
          params: {id: this.exploitationId}
        }
      },
      
      tabs() {
        return [
          {
            icon: 'fa fa-spinner',
            title: 'En cours',
            timeTitle: 'Débutée le',
            harvests: this.harvests.filter(item => item.status.name == 'RUNNING'),
            type: 'running'
          },
          {
            icon: 'fa fa-stop-circle',
            title: 'Terminées',
            timeTitle: 'Terminée le',
            harvests: this.harvests.filter(item => item.status.name == 'TERMINATED'),
            type: 'terminated'
          },
          {
            icon: 'fa fa-clock-o',
            title: 'Planifiées',
            timeTitle: 'Planifiée pour le',
            harvests: this.harvests.filter(item => item.status.name == 'PLANNED'),
            type: 'planned'
          },
          {
            icon: 'fa fa-th',
            title: 'Abandonnées',
            timeTitle: 'Abandonnée le',
            harvests: this.harvests.filter(item => item.status.name == 'ABANDONED'),
            type: 'abandoned'
          },
          {
            icon: 'fa fa-ban',
            title: 'Bloquées',
            timeTitle: 'Bloquée le',
            harvests: this.harvests.filter(item => item.status.name == 'BLOCKED'),
            type: 'blocked'
          },
        ]
      },
      endHarvestCultureQuantitiesState() {
        return !this.submittedAllCutlureQuantities ? this.endHarvestCultureQuantities.map(cult => ({
          warehouseId: null,
          realQuantity: null
        })) : this.endHarvestCultureQuantities.map(cult => ({
          warehouseId: cult.warehouseId != null ? null : false,
          realQuantity: Regex.isPositiveNumber(cult.realQuantity) ? null : false
        }))
      },
      widthToDisplay() {
        if (this.endHarvestCultureQuantities.length >= 2)
          return 6
        else return ''
      },
      sizeToDisplay() {
        if (this.endHarvestCultureQuantities.length >= 2)
          return 'lg'
        else return ''
      },
    },
    methods: {
      parcelUrl(parcel_id) {
        this.$router.push('/exploitation/' + this.exploitationId + '/parcels/create?edit=' + parcel_id)
      },
      onExport() {
        IO.exportDataTable(this.harvests, 'harvests', {
          title: 'Exploitation: ' + this.$store.getters.exploitationName,
          name: 'Liste des récoltes',
          headers: [
            {title: 'Aperçu', key: 'picture', width: 'auto', asset: this.asset},
            {title: 'Culture', key: 'culture.name', width: '*'},
            {title: 'Parcelle', key: 'parcel.name', width: '*'},
            {title: 'Quantité', key: 'qty', width: '*'},
            {title: 'Date', key: 'createdAt', width: 'auto'},
          ]
        })
      },
      launchHarvest(harv, state) {
        this.harvests = this.harvests.map(harvItem => {
          if (harvItem.id == harv.id)
            return {
              ...harvItem,
              launching: state == 'save-start',
              ending: state == 'close'
            }
          else return harvItem
        })
        if (state == 'close') {
          const runningCultures = harv.cultures.reduce((acc, val) => {
            if (val.status.name != 'TERMINATED')
              return acc.concat({
                cultureId: val.cultureId,
                cultureName: val.name,
              })
            else return acc
          }, [])
          
          if (runningCultures.length == 0)
            this.sendRequestLaunch(harv, state)
          else {
            this.addNewInformationsEndHarvestModal = true
            this.fetchingExistingWarehouse = true
            this.currentHarvest = harv
            this.endHarvestCultureQuantities = runningCultures.map(cult => ({
              ...cult,
              warehouseId: null,
              realQuantity: ''
            }))
            if (this.warehouses.length == 0) {
              Api.get('/exploitation/warehouse/list', {
                exploitationId: this.exploitationId,
              })
                .then(res => {
                  if (res.data.status === 'success' && res.data.data) {
                    this.warehouses = res.data.data.map(ware => ({
                      value: ware.id,
                      text: ware.name
                    }))
                  }
                  else {
                    this.error = res.data.error
                  }
                })
                .catch(error => {
                  this.error = {
                    message: 'Echec de la connexion au serveur'
                  }
                })
                .then(() => {
                  this.fetchingExistingWarehouse = false
                })
            } else {
              this.fetchingExistingWarehouse = false
            }
          }
        } else {
          this.sendRequestLaunch(harv, state)
        }
        
        
      },
      onEndHarvest(){
        this.submittedAllCutlureQuantities = true
        if (!this.validAllCultureQuantities()) return
        this.fetchingEnd = true
        this.sendRequestLaunch(this.currentHarvest,'close',this.endHarvestCultureQuantities)
      },
      validAllCultureQuantities() {
        this.endHarvestCultureQuantities.forEach(item => {
          if (item.warehouseId == null || Regex.isPositiveNumber(item.realQuantity))
            return false
        })
        return true
      },
      sendRequestLaunch(harv, state, endHarvestCultureQuantities = []) {
        
        Api.post(`/exploitation/production/harvest/${state}`, {
          exploitationId: this.exploitationId,
          parcelId: harv.production.parcel.id,
          productionId: harv.production.id,
          harvestId: harv.id,
          startDate: harv.startDate,
          name: harv.name,
          description: harv.description,
          cultures: harv.cultures,
          endHarvestCultureQuantities
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              const harvResult = res.data.data
              this.addNewInformationsEndHarvestModal = false
              this.harvests = this.harvests.map(harvItem => {
                if (harvItem.id == harv.id)
                  return {
                    ...harvItem,
                    status: harvResult.status,
                    launching: false,
                    ending: false
                  }
                else return harvItem
              })
              if (state == 'save-start') {
                Toast.success('Récolte démarrée avec succès !')
                if (harvResult.status.name == 'RUNNING')
                  this.harvestTabOpen = 0
                else
                  this.harvestTabOpen = 4
              }
              else {
                Toast.success('Récolte clôturée avec succès !')
                this.harvestTabOpen = 1
              }
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
          
          })
      },
      onModalClose() {
        this.fetchingExistingWarehouse = false
        this.submittedAllCutlureQuantities = false
        this.fetchingEnd = false
        this.endHarvestCultureQuantities = []
      },
      nextComposantToVisit(){
        this.$router.push('/exploitation/' + this.exploitationId + '/accounting/capitalEntries/list')
      }
    }
  }
</script>

<style scoped>
  parcel-name{
    font-weight: bold;
    font-size: 16px;
    font-family: "Verdana Pro Cond Black";
  }
  
  parcel-name:hover{
    color: #07C;
    text-decoration: underline;
    cursor: pointer;
  }
</style>
